import React, { useEffect } from 'react'
import logo from '../../../../../images/FinalLogo.jpg'
import Navbar from '../../../Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import '../../../../../services/GlobalVariables'
import { getAllInvoice } from '../../../../../features/reports/stock/purchase/purchaseReportSlice'
import Loading from '../../../../Loading'
function PurchaseReportAllInvoices() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { PRFORDB, isLoading } = useSelector((state) => state.PRDB)
    const { AllInvoices } = useSelector((state) => state.PRDB)
    const [EntDate, setEntDate] = React.useState(global.targetDate)

    useEffect(() => {
        if (!user) {
            navigate('/')
        }
        if (!PRFORDB) {
            const authData = { targetDate: EntDate !== '' ? dayjs(EntDate.$d).format('YYYY-MM-DD') : dayjs(new Date().$d).format('YYYY-MM-DD') }
            dispatch(getAllInvoice(authData))
        } else {
            if (!PRFORDB.PurchaseMaster) {
                const authData = { targetDate: EntDate !== '' ? dayjs(EntDate.$d).format('YYYY-MM-DD') : dayjs(new Date().$d).format('YYYY-MM-DD') }
                dispatch(getAllInvoice(authData))
            }
        }
    }, [user, navigate, dispatch, EntDate, PRFORDB])





    // @@@@@@@@@@@@@**************** For PDF Viewer
    //   Table Head
    const borderColor = 'white'
    const stylesTH = StyleSheet.create({
        container: {
            flexDirection: 'row',
            borderBottomColor: 'white',
            backgroundColor: 'grey',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 15,
            textAlign: 'center',
            flexGrow: 1,
            fontSize: '10px',
            fontFamily: 'TimesNewRoman'
        },
        thsno: {
            width: '8%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thcitemname: {
            width: '48%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thquantity: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thitemunit: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thitemrate: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thitemamount: {
            width: '12%',
        },
    });


    // Table Row

    const borderColorRow = 'black'
    const stylesTR = StyleSheet.create({
        row: {
            flexDirection: 'row',
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 20,
            textAlign: 'center',
            flexGrow: 1,
            fontFamily: 'TimesNewRoman',
            fontSize: 9
        },
        trsno: {
            width: '8%',
            height: '100%',
            textAlign: 'center',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 4,
        },
        tritemname: {
            width: '48%',
            height: '100%',
            textAlign: 'left',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        trquantity: {
            width: '10%',
            height: '100%',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        tritemunit: {
            width: '10%',
            height: '100%',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        tritemrate: {
            width: '10%',
            height: '100%',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        tritemamount: {
            width: '12%',
            height: '100%',
            textAlign: 'right',
            // paddingRight: 8,
        },
    });

    // Table Total Row

    const stylesTTR = StyleSheet.create({
        row: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            backgroundColor: 'black',
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            height: 20,
            textAlign: 'center',
            flexGrow: 1,
            fontFamily: 'TimesNewRoman'
        },
        ttrtc: {
            width: '56%',
            textAlign: 'center',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            fontSize: 10,
            paddingTop: 2,
            fontWeight: 'bold'
        },
        ttrtq: {
            width: '10%',
            textAlign: 'right',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 8,
            fontSize: 10,
            paddingTop: 2,
            fontWeight: 'bold'

        },
        ttrspace: {
            width: '20%',
            textAlign: 'right',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        ttrta: {
            width: '12%',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            // paddingRight: 8,
            fontSize: 10,
            paddingTop: 2,
            fontWeight: 'bold'

        },
    });

    //   Table Container 

    const stylesTC = StyleSheet.create({
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10,
            borderWidth: 1,
            borderColor: 'black',
            fontSize: '7px',
        },
    });

    return (
        <>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <Typography
                        style={{
                            color: '#3FD9FF',
                            fontSize: 35, fontWeight: 'bolder', borderColor: 'grey',
                            borderStyle: 'solid', borderWidth: 2, boxShadow: '1px 1px 5px black',
                            backgroundColor: '#1D4988', textShadow: '2px 2px 5px black', textAlign: 'center', borderRadius: 20
                        }}
                    >
                        Purchase Report</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ textAlign: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker sx={{ mt: 1, textAlign: 'center' }}
                                defaultValue={dayjs(global.targetDate)}
                                onChange={(newValue) => {
                                    setEntDate(newValue)
                                    global.targetDate = newValue
                                    const authData = { targetDate: global.targetDate !== '' ? dayjs(global.targetDate.$d).format('YYYY-MM-DD') : dayjs(new Date().$d).format('YYYY-MM-DD') }
                                    dispatch(getAllInvoice(authData))
                                }}
                                slotProps={{ textField: { size: 'small' } }} />
                        </LocalizationProvider></Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={12}>
                    {isLoading ? <Loading /> : AllInvoices.length > 0 ? 
                    <PDFViewer height={880} width={'100%'}>
                    <Document>
                        {AllInvoices ? AllInvoices.map(({PartyName, EntDate, DONO, SuppInvNo, Detail}) => (
                                <Page size={{ width: 8 * 72, height: 11 * 72 }}>
                                    <View style={{ flexDirection: 'row', marginTop: '10px', marginLeft: 10 }}>
                                        <Image style={{ height: '50px', width: '80px' }} src={logo} />
                                        <View style={{ marginLeft: '10px' }}>
                                            <Text style={{ fontSize: '15px' }}>Lifcon Steel Industry</Text>
                                            <Text style={{ fontSize: '9px' }}>Plot #A-59 & A-59(A) Lasbela Industrial Estate Development Athority H.I.T.E. Hub Balochistan.</Text>
                                            <Text style={{ fontSize: '9px' }}>0853310096</Text>
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '20px', textAlign: 'center' }}>Purchase Invoice</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '10px', textAlign: 'left' }}>EntDate : {dayjs(EntDate).format('DD-MMM-YYYY') }</Text>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '10px', textAlign: 'left' }}>Invoice No : {DONO}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '10px', textAlign: 'left' }}>Supplier Name : {PartyName}</Text>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '10px', textAlign: 'left' }}>Supp Inv No : {SuppInvNo}</Text>
                                </View>
                                <View style={stylesTC.tableContainer}>
                                    <View style={stylesTH.container}>
                                        <Text style={stylesTH.thsno}>S.No</Text>
                                        <Text style={stylesTH.thcitemname}>Item Name</Text>
                                        <Text style={stylesTH.thquantity}>Quantity</Text>
                                        <Text style={stylesTH.thitemunit}>Item Unit</Text>
                                        <Text style={stylesTH.thitemrate}>Item Rate</Text>
                                        <Text style={stylesTH.thitemamount}>Item Amount</Text>
                                    </View>

                                    {isLoading ? (<View><Text style={{ fontSize: '30px', fontWeight: 'bold', fontFamily: 'TimesNewRoman' }}>Loading Please Wait</Text></View>) : (
                                        Detail.map((item, index) => (
                                            <View style={stylesTR.row} key={Math.random.toString()}>
                                                <Text style={stylesTR.trsno}>{index+1}</Text>
                                                <Text style={stylesTR.tritemname}>{item.ItemName}</Text>
                                                <Text style={stylesTR.trquantity}>{Math.round(item.Quantity).toLocaleString("en-US")}</Text>
                                                <Text style={stylesTR.tritemunit}>{item.ItemUnit}</Text>
                                                <Text style={stylesTR.tritemrate}>{Math.round(item.RR).toLocaleString("en-US")}</Text>
                                                <Text style={stylesTR.tritemamount}>{Math.round(item.RR * item.Quantity).toLocaleString("en-US")}</Text>
                                            </View>))
                                    )}
                                    <View style={stylesTTR.row}>
                                        <Text style={stylesTTR.ttrtc}>TOTAL</Text>
                                        <Text style={stylesTTR.ttrtq}>{Math.round(Detail.reduce((sum, item) => sum + item.Quantity, 0)).toLocaleString("en-US")}</Text>
                                        <Text style={stylesTTR.ttrspace}></Text>
                                        <Text style={stylesTTR.ttrta}>{Math.round(Detail.reduce((sum, item) => sum + (item.Quantity * item.RR), 0)).toLocaleString("en-US")}</Text>
                                    </View>

                                </View>
                                </Page>
                        )) : <></>}
                            </Document>
                    </PDFViewer>
                    : <Typography style={{textAlign:'center', fontSize:30, fontWeight:'bolder'}}>Data Not Found</Typography>}
                    
                </Grid>

            </Grid>
        </>
    )
}

export default PurchaseReportAllInvoices
