import axios from 'axios'
import '../../../../services/GlobalVariables'

const API_URL = global.SET_API_URL + "report/sales/"

const getTotalingForDashBaord = async (authData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${authData.UserID}/${authData.targetDate}`
        }
    }

    // const response = await axios.get(API_URL + 'getMasterData')
    const response = await axios.get(API_URL, config)
    return response.data
}

const getInvoice = async (InvData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${InvData.UserID}/${InvData.InvoiceNo}`
        }
    }

    // const response = await axios.get(API_URL + 'getMasterData')
    const response = await axios.get(API_URL + 'getInvoice/', config)
    return response.data
}

const getAllInvoice = async (authData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${authData.targetDate}`
        }
    }

    // const response = await axios.get(API_URL + 'getMasterData')
    const response = await axios.get(API_URL + 'getAllInvoices/', config)
    return response.data
}

const getGeneralSalesData = async (reportParams) => {
    console.log(reportParams)

    // const config = {
    //     headers: {
    //         Authorization: `Bearer /${dataForLedger.PartyName}/${dataForLedger.FromDate}/${dataForLedger.ToDate}/${dataForLedger.Currency}/${dataForLedger.CBDetail}`
    //     }
    // }

    const response = await axios.post(API_URL + 'generalSalesReport', reportParams)
    // const response = await axios.get(API_URL + 'getMasterData', config)
    return response.data
}

const partyLedgerService = { getTotalingForDashBaord, getInvoice, getAllInvoice, getGeneralSalesData }

export default partyLedgerService