import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import salesReportService from './salesReportService'


const initialState = {
    SRFORDB: [],
    SID: [],
    AllInvoices: [],
    generalSalesData: [],
    isLoading: false,
    isLoadingForDetail: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getTotalingForDashBaord = createAsyncThunk('SR/SMFD', async (authData, thunkAPI) => {
    try {
        return await salesReportService.getTotalingForDashBaord(authData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getInvoice = createAsyncThunk('SR/SID', async (InvData, thunkAPI) => {
    try {
        return await salesReportService.getInvoice(InvData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getAllInvoice = createAsyncThunk('SR/AllInv', async (InvData, thunkAPI) => {
    try {
        return await salesReportService.getAllInvoice(InvData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getGeneralSalesData = createAsyncThunk('SR/GS', async (reportParams, thunkAPI) => {
    try {
        return await salesReportService.getGeneralSalesData(reportParams)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const salesHistorySlice = createSlice({
    name: 'salesHistoryService',
    initialState,
    reducers: {
        reset: (state) => {
            state.SRFORDB = []
            state.SID = []
            state.generalSalesData = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTotalingForDashBaord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTotalingForDashBaord.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.SRFORDB = action.payload
            })
            .addCase(getTotalingForDashBaord.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.SRFORDB = []
            })
            .addCase(getInvoice.pending, (state) => {
                state.isLoadingForDetail = true
            })
            .addCase(getInvoice.fulfilled, (state, action) => {
                state.isLoadingForDetail = false
                state.isSuccess = true
                state.SID = action.payload
            })
            .addCase(getInvoice.rejected, (state, action) => {
                state.isLoadingForDetail = false
                state.isError = true
                state.message = action.payload
                state.SID = []
            })
            .addCase(getAllInvoice.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllInvoice.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.AllInvoices = action.payload
            })
            .addCase(getAllInvoice.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.AllInvoices = []
            })
            .addCase(getGeneralSalesData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getGeneralSalesData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.generalSalesData = action.payload
            })
            .addCase(getGeneralSalesData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.generalSalesData = []
            })
    }
})

export const { reset } = salesHistorySlice.actions

export default salesHistorySlice.reducer