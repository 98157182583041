import axios from 'axios'
import '../../../services/GlobalVariables'

const API_URL = global.SET_API_URL + "report/Accounts/"

const getSalesAgingReport = async (dataForLedger) => {
    // const config = {
    //     headers: {
    //         Authorization: `Bearer /${dataForLedger.PartyName}`
    //     }
    // }
    const response = await axios.post(API_URL + 'salesAgingReport', dataForLedger)
    return response.data
}

const partyLedgerService = { getSalesAgingReport }

export default partyLedgerService