import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import accountsReportService from './accountsReportService'


const initialState = {
    salesAgingData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getSalesAgingReport = createAsyncThunk('acc/sar', async (dataForSAR, thunkAPI) => {
    try {
        return await accountsReportService.getSalesAgingReport(dataForSAR)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSalesAgingReportSlice = createSlice({
    name: 'accountReport',
    initialState,
    reducers: {
        reset: (state) => {
            state.salesAgingData = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSalesAgingReport.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSalesAgingReport.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.salesAgingData = action.payload
            })
            .addCase(getSalesAgingReport.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.salesAgingData = []
            })
    }
})

export const { reset } = getSalesAgingReportSlice.actions

export default getSalesAgingReportSlice.reducer