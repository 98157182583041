import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import productionService from './productionService'


const initialState = {
    productionList: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getProductionList = createAsyncThunk('Prod/gpl', async (dateData, thunkAPI) => {
    try {
        console.log(dateData)
        return await productionService.getProductionList(dateData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const stockSlice = createSlice({
    name: 'PurchaseMaster',
    initialState,
    reducers: {
        reset: (state) => {
            state.productionList = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""

        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductionList.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProductionList.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.productionList = action.payload
            })
            .addCase(getProductionList.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.Prod = []
            })
    }
})

export const { reset } = stockSlice.actions

export default stockSlice.reducer