import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import purchaseReportService from './purchaseReportService'


const initialState = {
    PRFORDB: [],
    PID:[],
    AllInvoices:[],
    isLoading: false,
    isLoadingForDetail: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getPurTotalingForDashBaord = createAsyncThunk('PR/PMFD', async(authData, thunkAPI)=>{
    try {
        return await purchaseReportService.getTotalingForDashBaord(authData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getInvoice = createAsyncThunk('PR/PID', async(InvData, thunkAPI)=>{
    try {
        return await purchaseReportService.getInvoice(InvData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getAllInvoice = createAsyncThunk('PR/AllInv', async(InvData, thunkAPI)=>{
    try {
        return await purchaseReportService.getAllInvoice(InvData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const purchaseReportSlice = createSlice({
    name: 'purchaseReportService',
    initialState,
    reducers:{
        reset:(state)=>{
            state.PRFORDB =  []
            state.PID = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers:(builder)=>{
        builder
            .addCase(getPurTotalingForDashBaord.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getPurTotalingForDashBaord.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.PRFORDB = action.payload
            })
            .addCase(getPurTotalingForDashBaord.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.PRFORDB = []
            })
            .addCase(getInvoice.pending, (state)=>{
                state.isLoadingForDetail = true
            })
            .addCase(getInvoice.fulfilled, (state, action)=>{
                state.isLoadingForDetail = false
                state.isSuccess = true
                state.PID = action.payload
            })
            .addCase(getInvoice.rejected, (state, action)=>{
                state.isLoadingForDetail = false
                state.isError = true
                state.message = action.payload
                state.PID = []
            })
            .addCase(getAllInvoice.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getAllInvoice.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.AllInvoices = action.payload
            })
            .addCase(getAllInvoice.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.AllInvoices = []
            })
        }
})

export const { reset } = purchaseReportSlice.actions

export default purchaseReportSlice.reducer