import axios from 'axios'
import '../../../../services/GlobalVariables'

const API_URL = global.SET_API_URL + "report/purchase/"

const getData = async(authdata)=>{
    const config = {
        headers: {
            Authorization: `Bearer ${authdata.UserID}/${authdata.EntDate}`
        }
    }
    console.log(config)
    const response = await axios.get(API_URL, config)
    // const response = await axios.get(API_URL + 'getMasterData', config)
    return response.data
}


const partyLedgerService = { getData }

export default partyLedgerService