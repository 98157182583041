import axios from 'axios'
import '../../../services/GlobalVariables'

const API_URL = global.SET_API_URL + "report/production/"
const getProductionList = async (dateData) => {

    const response = await axios.post(API_URL + 'productionList', dateData)
    return response.data
}


const productionService = { getProductionList }

export default productionService