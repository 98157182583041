import React, { useEffect } from 'react'
import logo from '../../../../../images/FinalLogo.jpg'
import Loading from '../../../../Loading'
import Navbar from '../../../Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PrintIcon from '@mui/icons-material/Print';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import '../../../../../services/GlobalVariables'
import { getTotalingForDashBaord, getInvoice } from '../../../../../features/reports/stock/sales/salesReportSlice'

function SalesReportMaster() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { SRFORDB, isLoading, isLoadingForDetail } = useSelector((state) => state.SRDB)
    const { SalesInvoiceDetail } = useSelector((state) => state.SRDB.SID)
    const { SalesInvoiceMaster } = useSelector((state) => state.SRDB.SID)
    const [EntDate, setEntDate] = React.useState(global.targetDate)

    useEffect(() => {
        if (!user) {
            navigate('/')
        }
        if (!SRFORDB) {
            const authData = { UserID: user[0].ID, targetDate: EntDate !== '' ? dayjs(EntDate.$d).format('YYYY-MM-DD') : dayjs(new Date().$d).format('YYYY-MM-DD') }
            dispatch(getTotalingForDashBaord(authData))
        } else {
            if (!SRFORDB.SalesMaster) {
                const authData = { UserID: user[0].ID, targetDate: EntDate !== '' ? dayjs(EntDate.$d).format('YYYY-MM-DD') : dayjs(new Date().$d).format('YYYY-MM-DD') }
                dispatch(getTotalingForDashBaord(authData))
            }
        }
    }, [user, navigate, dispatch, EntDate, SRFORDB, SalesInvoiceDetail])





    // @@@@@@@@@@@@@**************** For PDF Viewer
    //   Table Head
    const borderColor = 'white'
    const stylesTH = StyleSheet.create({
        container: {
            flexDirection: 'row',
            borderBottomColor: 'white',
            backgroundColor: 'grey',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 15,
            textAlign: 'center',
            flexGrow: 1,
            fontSize: '10px',
            fontFamily: 'TimesNewRoman'
        },
        thevno: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thvhicelno: {
            width: '8%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thnos: {
            width: '8%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thcitemname: {
            width: '30%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thquantity: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thitemunit: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thitemrate: {
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        thitemamount: {
            width: '12%',
        },
    });


    // Table Row

    const borderColorRow = 'black'
    const stylesTR = StyleSheet.create({
        row: {
            flexDirection: 'row',
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 25,
            textAlign: 'center',
            flexGrow: 1,
            fontFamily: 'TimesNewRoman',
            fontSize:9
        },
        trvno: {
            width: '10%',
            height: '100%',
            textAlign: 'center',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 5,
            fontSize:8
        },
        trvhicelno: {
            width: '8%',
            height: '100%',
            paddingTop: 5,
            textAlign: 'left',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 4,
        },
        trnos: {
            width: '8%',
            height: '100%',
            paddingTop: 5,
            textAlign: 'right',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingRight: 8,
        },
        tritemname: {
            width: '30%',
            height: '100%',
            paddingTop: 5,
            textAlign: 'left',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        trquantity: {
            width: '10%',
            height: '100%',
            paddingTop: 5,
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        tritemunit: {
            width: '10%',
            height: '100%',
            paddingTop: 5,
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        tritemrate: {
            width: '10%',
            height: '100%',
            paddingTop: 5,
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            paddingRight: 8,
        },
        tritemamount: {
            width: '12%',
            height: '100%',
            paddingTop: 5,
            textAlign: 'right',
            // paddingRight: 8,
        },
    });

    // Table Total Row

    const stylesTTR = StyleSheet.create({
        row: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            backgroundColor: 'black',
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            height: 20,
            textAlign: 'center',
            flexGrow: 1,
            fontFamily: 'TimesNewRoman'
        },
        ttrtc: {
            width: '56%',
            textAlign: 'center',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            fontSize: 10,
            paddingTop: 2,
            fontWeight: 'bold'
        },
        ttrtq: {
            width: '10%',
            textAlign: 'right',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 8,
            fontSize: 10,
            paddingTop: 2,
            fontWeight: 'bold'

        },
        ttrspace: {
            width: '20%',
            textAlign: 'right',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        ttrta: {
            width: '12%',
            color: 'white',
            borderRightColor: borderColorRow,
            borderRightWidth: 1,
            textAlign: 'right',
            // paddingRight: 8,
            fontSize: 10,
            paddingTop: 2,
            fontWeight: 'bold'

        },
    });

    //   Table Container 

    const stylesTC = StyleSheet.create({
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10,
            borderWidth: 1,
            borderColor: 'black',
            fontSize: '7px',
        },
    });

    return (
        <>
            <Navbar />
            <Grid container spacing={2}>
            <Grid item xs={4}>
                    <Typography style={{ textAlign: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker sx={{ mt: 1, textAlign: 'center' }}
                                defaultValue={dayjs(global.targetDate)}
                                onChange={(newValue) => {
                                    setEntDate(newValue)
                                    global.targetDate = newValue
                                    const authData = { UserID: user[0].ID, targetDate: global.targetDate !== '' ? dayjs(global.targetDate.$d).format('YYYY-MM-DD') : dayjs(new Date().$d).format('YYYY-MM-DD') }
                                    dispatch(getTotalingForDashBaord(authData))
                                }}
                                slotProps={{ textField: { size: 'small' } }} />
                        </LocalizationProvider></Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography 
                    style={{color:'yellow', 
                    fontSize:35, fontWeight:'bolder', borderColor:'grey', 
                    borderStyle:'solid', borderWidth:2, boxShadow:'1px 1px 5px black', 
                    backgroundColor:'#1D4988', textShadow:'2px 2px 5px black', textAlign:'center', borderRadius:20}}
                    >
                    Sales Report</Typography>
            </Grid>
                <Grid item xs={4}><Button style={{width:'100%'}} onClick={()=>navigate('/Reports/SalesReportAllInvoices')}>
                <Typography 
                    style={{paddingLeft:50, paddingRight:50, color:'#fff', 
                    fontSize:25, fontWeight:'bolder', borderColor:'grey', 
                    borderStyle:'solid', borderWidth:2, 
                    backgroundColor:'#1D4988', textAlign:'center', borderRadius:20}}
                    >
                        Get All Invoices
                </Typography></Button>
                </Grid>

                <Grid item xs={6}>
                    <TableContainer component={Paper} sx={{ height: 420 }}>
                        <Table sx={{ width: '100%', fontSize: 50 }} aria-label="simple table">
                            <TableHead sx={{ bgcolor: 'primary.main', fontFamily: 'Arial' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'custom.main', border: 1, borderColor: '#fff', width: '10%' }} >Invoice No</TableCell>
                                    <TableCell sx={{ color: 'custom.main', border: 1, borderColor: '#fff', width: '50%' }} >Party Name</TableCell>
                                    <TableCell sx={{ color: 'custom.main', border: 1, borderColor: '#fff', width: '5%' }}>No of Items</TableCell>
                                    <TableCell sx={{ color: 'custom.main', border: 1, borderColor: '#fff', width: '5%' }}>Amount</TableCell>
                                    <TableCell sx={{ color: 'custom.main', border: 1, borderColor: '#fff', width: '4%' }}>Print</TableCell>
                                </TableRow>
                            </TableHead>
                            {isLoading ? (<><Loading /></>) : (<></>)}
                            <TableBody sx={{ fontFamily: 'Times New Roman', color: '#000' }}>
                                {SRFORDB.SalesMaster ? (SRFORDB.SalesMaster.map((item, index) => (
                                    <TableRow style={{ backgroundColor: '#799CFF', color: '#fff' }}
                                        sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                                    >
                                        <TableCell sx={{ border: 1, borderColor: 'primary.main', color: '#000', textAlign: 'center', fontSize: '12px', p: 0 }}>{item.InvoiceNo}</TableCell>
                                        <TableCell sx={{ border: 1, borderColor: 'primary.main', textAlign: 'left', color: '#000', fontSize: '12px', p: 0 }}>{item.PartyName}</TableCell>
                                        <TableCell sx={{ border: 1, borderColor: 'primary.main', textAlign: 'center', color: '#000', fontSize: '12px', p: 0 }}>{item.NOI}</TableCell>
                                        <TableCell sx={{ border: 1, borderColor: 'primary.main', textAlign: 'center', color: '#000', fontSize: '12px', p: 0 }}>{item.TotalAmount}</TableCell>
                                        <TableCell sx={{ border: 1, borderColor: 'primary.main', textAlign: 'right', color: '#000', p: 0 }}>
                                            <Button
                                                sx={{ color: '#000', alignSelf: 'center', p: 0 }}
                                                onClick={() => {
                                                    const InvData = { UserID: item.UserID, InvoiceNo: item.InvoiceNo }
                                                    dispatch(getInvoice(InvData))
                                                    // setmainUserData({PDRONO:item.PDRONO, UserID:item.UserID, PartyCode:item.Code})
                                                }}
                                                size='small'
                                            ><PrintIcon fontSize='small' /></Button></TableCell>
                                    </TableRow>
                                ))) : <>Not Found</>}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
                <Grid item xs={6}>
                    <PDFViewer height={880} width={'97%'}>
                        <Document title={SalesInvoiceMaster ? `${SalesInvoiceMaster[0].PartyName}_Invoice No_${SalesInvoiceMaster[0].InvoiceNo}_Date_${dayjs(SalesInvoiceMaster[0].EntDate).format('DD-MMM-YYYY')}.pdf` : 'Not Name'}>
                            <Page size={{ width: 8 * 72, height: 11 * 72 }}>
                                <View style={{ flexDirection: 'row', marginTop: '10px', marginLeft: 10 }}>
                                    <Image style={{ height: '50px', width: '80px' }} src={logo} />
                                    <View style={{ marginLeft: '10px' }}>
                                        <Text style={{ fontSize: '15px' }}>Lifcon Steel Industry</Text>
                                        <Text style={{ fontSize: '9px' }}>Plot #A-59 & A-59(A) Lasbela Industrial Estate Development Athority H.I.T.E. Hub Balochistan.</Text>
                                        <Text style={{ fontSize: '9px' }}>0853310096</Text>

                                    </View>
                                </View>
                                <View>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '20px', textAlign: 'center' }}>Sales Invoice</Text>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '10px', textAlign: 'left' }}>EntDate : {SalesInvoiceDetail ? SalesInvoiceDetail[0].EntDate ? dayjs(SalesInvoiceDetail[0].EntDate).format('DD-MMM-YYYY') : '' : ''}</Text>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '10px', textAlign: 'left' }}>Invoice No : {SalesInvoiceDetail ? SalesInvoiceDetail[0].InvoiceNo ? SalesInvoiceDetail[0].InvoiceNo : '' : ''}</Text>
                                </View>
                                <View>
                                    <Text style={{ marginTop: '10px', fontFamily: 'TimesNewRoman', fontSize: '12px', textAlign: 'center' }}>Party Name : {SalesInvoiceMaster ? SalesInvoiceMaster[0].PartyName ? SalesInvoiceMaster[0].PartyName : '' : ''}</Text>
                                </View>
                                <View style={stylesTC.tableContainer}>
                                    <View style={stylesTH.container}>
                                        <Text style={stylesTH.thevno}>V.No</Text>
                                        <Text style={stylesTH.thvhicelno}>Vehicle #</Text>
                                        <Text style={stylesTH.thnos}>Nos</Text>
                                        <Text style={stylesTH.thcitemname}>Item Name</Text>
                                        <Text style={stylesTH.thquantity}>Quantity</Text>
                                        <Text style={stylesTH.thitemunit}>Item Unit</Text>
                                        <Text style={stylesTH.thitemrate}>Item Rate</Text>
                                        <Text style={stylesTH.thitemamount}>Item Amount</Text>
                                    </View>
                                    {isLoadingForDetail ? (<View><Text style={{ fontSize: '30px', fontWeight: 'bold', fontFamily: 'TimesNewRoman' }}>Loading Please Wait</Text></View>) : (
                                        SalesInvoiceDetail ? SalesInvoiceDetail.map(item => (
                                            <View style={stylesTR.row} key={Math.random.toString()}>
                                                <Text style={stylesTR.trvno}>{item.Mill} {'\n'} {item.DCNO}</Text>
                                                <Text style={stylesTR.trvhicelno}>{item.VehicleNo}</Text>
                                                <Text style={stylesTR.trnos}>0</Text>
                                                <Text style={stylesTR.tritemname}>{item.ItemName}</Text>
                                                <Text style={stylesTR.trquantity}>{Math.round(item.Quantity).toLocaleString("en-US")}</Text>
                                                <Text style={stylesTR.tritemunit}>{item.ItemUnit}</Text>
                                                <Text style={stylesTR.tritemrate}>{Math.round(item.ItemRate).toLocaleString("en-US")}</Text>
                                                <Text style={stylesTR.tritemamount}>{Math.round(item.ItemRate * item.Quantity).toLocaleString("en-US")}</Text>
                                                {/* <Text style={{
                                                width: '10%',
                                                borderRightColor: borderColorRow,
                                                borderRightWidth: 1,
                                                textAlign: 'right',
                                                paddingRight: 8,
                                                color:`${item.Balance > 0 ? "green" : "red"}`
                                    }}>{item.Balance < 0 ? Math.round(item.Balance - item.Balance - item.Balance).toLocaleString("en-US") : Math.round(item.Balance).toLocaleString("en-US")}</Text>
                                    <Text style={{
                                                width: '10%',
                                                textAlign: 'left',
                                                borderRightColor: borderColor,
                                                borderRightWidth: 1,
                                                paddingLeft: 8,
                                                color:`${item.Balance > 0 ? "green" : "red"}`
                                        
                                    }}>{item.Balance > 0 ? "Debit" : "Credit"}</Text> */}
                                                {/* <Text style={stylesTH.rate}>{item.rate}</Text>
                                    <Text style={stylesTH.amount}>{(item.qty * item.rate).toFixed(2)}</Text> */}
                                            </View>)) : (<View></View>)
                                    )}
                                    <View style={stylesTTR.row}>
                                        <Text style={stylesTTR.ttrtc}>TOTAL</Text>
                                        <Text style={stylesTTR.ttrtq}>{SalesInvoiceDetail ? SalesInvoiceDetail.length > 0 ? Math.round(SalesInvoiceDetail.reduce((sum, item) => sum + item.Quantity, 0)).toLocaleString("en-US") : '0' : '0'}</Text>
                                        <Text style={stylesTTR.ttrspace}></Text>
                                        <Text style={stylesTTR.ttrta}>{SalesInvoiceDetail ? SalesInvoiceDetail.length > 0 ? Math.round(SalesInvoiceDetail.reduce((sum, item) => sum + (item.Quantity * item.ItemRate), 0)).toLocaleString("en-US") : '0' : '0'}</Text>
                                    </View>

                                </View>
                                {SalesInvoiceMaster ?
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20, }}>
                                        <Text style={{ marginLeft: 10, paddingLeft: 5, paddingTop: 2, fontFamily: 'TimesNewRoman', fontSize: 10, borderWidth: 1, borderColor: 'black', }}>Expenses || Transportation : {SalesInvoiceMaster[0].TrChtoBuyer === 'False' ? Math.round(SalesInvoiceMaster[0].TrAmount).toLocaleString("en-US") : '0'} Load/Unload : {SalesInvoiceMaster[0].LUChtoBuyer === 'False' ? Math.round(SalesInvoiceMaster[0].LUChAmount).toLocaleString("en-US") : '0'} Other Exp : {Math.round(SalesInvoiceMaster[0].OthExpAmount).toLocaleString("en-US")}</Text>
                                        <View>
                                            <Text style={{ marginRight: 10, paddingTop: 2, fontFamily: 'TimesNewRoman', fontSize: 10 }}>{SalesInvoiceMaster[0].LUChtoBuyer === 'False' && SalesInvoiceMaster[0].LUChtoBuyer === 'False' ? 'Total Exp : ' + Math.round(parseInt(SalesInvoiceMaster[0].TrAmount) + parseInt(SalesInvoiceMaster[0].LUChAmount) + parseInt(SalesInvoiceMaster[0].OthExpAmount)).toLocaleString("en-US") : ''}</Text>
                                        </View>
                                    </View>
                                    : <></>}
                                <View>
                                    <Text style={{ marginRight: 10, paddingTop: 2, textAlign: 'right', fontFamily: 'TimesNewRoman', fontSize: 10 }}>Total Bill Amount : {SalesInvoiceDetail ? SalesInvoiceDetail.length > 0 ? Math.round(parseInt(SalesInvoiceDetail.reduce((sum, item) => sum + (item.Quantity * item.ItemRate), 0)) + parseInt(SalesInvoiceMaster[0].TrAmount) + parseInt(SalesInvoiceMaster[0].LUChAmount) + parseInt(SalesInvoiceMaster[0].OthExpAmount)).toLocaleString("en-US") : '0' : '0'}</Text>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                </Grid>

            </Grid>
        </>
    )
}

export default SalesReportMaster
