import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import stockReducer from '../features/reports/stockSlice'
import menuReducer from '../features/menu/menuSlice'
import reusableReducer from '../features/resuable/reusableSlice';
import partyLedger from '../features/reports/partyLedger/partyLedgerSlice'
import accountsReportReducer from '../features/reports/accountsReports/accountsReportSlice'
import salesReportReducer from '../features/reports/stock/sales/salesReportSlice'
import purchaseReportReducer from '../features/reports/stock/purchase/purchaseReportSlice'
// import purchaseReducer from '../features/stock/purchase/purchaseDO/purchaseSlice'
// import purchaseDeliveryGRNReducer from '../features/stock/purchase/purchaseDeliveryGRN/purchaseDeliveryGRNSlice'
// import SalesDeliveryGDNReducer from '../features/stock/sales/salesDeliveryGDNSlice'
import ProductionReducer from '../features/reports/production/productionSlice'
// import physicalAuditReducer from '../features/stock/physicalAuditSlice'
// import purchaseMasterReducer from '../features/reports/stock/purchase/purchaseSlice'
// import stockAdjustmentReducer from '../features/stock/stockAdjustmentSlice'
import purchaseHistoryMasterDataReducer from '../features/reports/stock/purchase/purchaseHistorySlice'
// import salesHistoryMasterDataReducer from '../features/reports/stock/sales/salesHistorySlice'


export const store = configureStore({
  reducer: {
    auth: authReducer,
    stock: stockReducer,
    menu: menuReducer,
    SRDB: salesReportReducer,
    PRDB: purchaseReportReducer,
    // purchase: purchaseReducer,
    // purDeliGRN: purchaseDeliveryGRNReducer,
    // saleDeliGDN: SalesDeliveryGDNReducer,
    // physicalAudit: physicalAuditReducer,
    // PMData:purchaseMasterReducer,
    Prod: ProductionReducer,
    reuse: reusableReducer,
    // StockAdj: stockAdjustmentReducer,
    PL: partyLedger,
    PHMD: purchaseHistoryMasterDataReducer,
    accReport: accountsReportReducer
    // SHMD: salesHistoryMasterDataReducer,
  },
});
